import React from "react"

const Newsletter = () => (
  <div className="bg-blue-400">
  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
    <div className="lg:w-0 lg:flex-1">
      <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-blue-900 sm:text-4xl sm:leading-10">
        Sign up for my newsletter
      </h2>
      <p className="mt-3 max-w-3xl text-lg leading-6 text-blue-900">
        Stay up to date when I release courses, posts, and anything related to GraphQL
      </p>
    </div>
    <div className="mt-8 lg:mt-0 lg:ml-8">
      <form className="sm:flex" action="https://productionreadygraphql.us18.list-manage.com/subscribe/post?u=f6aeae7358e58b6830072d07b&amp;id=12d4602640" method="post">
        <input aria-label="Email address" type="email" required className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" />
        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-pink-500 hover:bg-pink-600 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
            Sign me Up
          </button>
        </div>
      </form>
      <p className="mt-3 text-sm leading-5 text-blue-900">
        No spam, just great GraphQL content!
      </p>
    </div>
  </div>
</div>
)

export default Newsletter