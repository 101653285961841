import React from "react"

const Footer = () => (
  <div className="bg-gray-50 py-12 border-t border-gray-200 ">
    <div>
      <p className="text-base leading-6 text-gray-400 xl:text-center">
        &copy; 2020 MYUL Digital, Inc. All rights reserved.
      </p>
    </div>
  </div>
)

export default Footer